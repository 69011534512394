/* Bebas Neue */
@font-face {
  font-family: 'Bebas Neue';
  src: url('../fonts/BebasNeue-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

/* Noto Serif */
@font-face {
  font-family: 'Noto Serif';
  src: url('../fonts/NotoSerif-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Noto Serif';
  src: url('../fonts/NotoSerif-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Noto Serif';
  src: url('../fonts/NotoSerif-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Noto Serif';
  src: url('../fonts/NotoSerif-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

/* Inter */
@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

:root {
  --bettr-yellow: #ffc200;
  --cocoa: #f0730a;
  --twilight: #3c566d;
  --process-black: #000000;
  --au-lait: #f9f6e8;
  --aut-lait-bgd: #f1eedb;
  --au-lait-bgd-2: #fcfbf6;
  --latte: #e3ddd2;
  --mocha: #b7a99a;
  --french: #3f4444;
  --wine: #c80000;
  --berry: #21145f;
  --grape: #3bd4ae;
  --molasses: #514a38;
  --magenta: #a4004f;
  --green: #cff6eb;
  --lightwine: #f7cfd6;
  --bebas: 'Bebas Neue';
  --noto: 'Noto Serif';
  --inter: 'Inter';
  --headline-1: 40px;
  --large-title: 34px;
  --headline-2: 28px;
  --headline-3: 22px;
  --body-20: 20px;
  --body-17: 17px;
  --body-15: 15px;
  --caption-13: 13px;
  --caption-11: 11px;
  --noto-tagline: 15px;
  --inter-tagline: 9px;
}

/* Basic Settings */
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  background-color: #f9f6e8 !important;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
body {
  color: var(--french) !important;
  line-height: 20.43px;
}

/* General Style Classes */
.lh-1 {
  line-height: 1;
}

.m-0 {
  margin: 0;
}

/* Typography */
.h1 {
  font-family: var(--bebas);
  font-size: var(--headline-1);
}

.large-title {
  font-family: var(--bebas);
  font-size: var(--large-title);
}

.h2 {
  font-family: var(--noto);
  font-size: var(--headline-2);
}

.h2-sb {
  font-family: var(--noto);
  font-size: var(--headline-2);
  font-weight: 600;
}

.h3 {
  font-family: var(--noto);
  font-size: var(--headline-3);
}

.h3-sb {
  font-family: var(--noto);
  font-size: var(--headline-3);
  font-weight: 600;
}

.noto-body-20 {
  font-family: var(--noto);
  font-size: var(--body-20);
}

.noto-body-20-sb {
  font-family: var(--noto);
  font-size: var(--body-20);
  font-weight: 600;
}

.noto-body-17 {
  font-family: var(--noto);
  font-size: var(--body-17);
}

.noto-body-15 {
  font-family: var(--noto);
  font-size: var(--body-15);
}

.noto-caption-13 {
  font-family: var(--noto);
  font-size: var(--caption-13);
}

.noto-caption-11 {
  font-family: var(--noto);
  font-size: var(--caption-11);
}

.noto-tagline {
  font-family: var(--noto);
  font-size: var(--noto-tagline);
  text-transform: uppercase;
  opacity: 40%;
}

.inter-body-20 {
  font-family: var(--inter);
  font-size: var(--body-20);
}

.inter-body-20-sb {
  font-family: var(--inter);
  font-size: var(--body-20);
  font-weight: 600;
}

.inter-body-17 {
  font-family: var(--inter);
  font-size: var(--body-17);
}

.inter-body-15 {
  font-family: var(--inter);
  font-size: var(--body-15);
}

.inter-body-15-sb {
  font-family: var(--inter);
  font-size: var(--body-15);
  font-weight: 600;
}

.inter-body-16-sb {
  color: black;
  display: block;
  text-align: right;
  align-items: flex-end;
  font-family: var(--inter);
  font-size: var(--body-15);
  font-weight: 600;
}

.inter-caption-13 {
  font-family: var(--inter);
  font-size: var(--caption-13);
}

.inter-caption-11 {
  font-family: var(--inter);
  font-size: var(--caption-11);
}

.inter-tagline {
  font-family: var(--inter);
  font-size: var(--inter-tagline);
}

.FormGroup {
  margin: 0 15px 20px;
  padding: 0;
  border-style: none;
  background-color: #7795f8;
  will-change: opacity, transform;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #829fff;
  border-radius: 4px;
}

.FormRow {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 15px;
  border-top: 1px solid #819efc;
}

.StripeElement--webkit-autofill {
  background: transparent !important;
}

.StripeElement {
  width: 100%;
  padding: 11px 15px 11px 0;
}

.disactive {
  opacity: 0.5;
}